import { ref, reactive } from 'vue';

export function useProperty() {

    // const selectedPropertyType = ref('');
    // const selectedCurrency = ref('');



    const getPropertyDetails = async (userId:any) => {
        try {
           const response:any = await $fetch(`/api/propertyController/property?userId=${encodeURIComponent(userId)}`, {
                method: 'GET',
            })
            
            return response
        } catch (error) {
            console.error("Error fetching property details: ", error);
        }
    };

    const getPropertyId = async (userId:any) => {
       const data = await $fetch(`/api/propertyController/property?userId=${userId}`, {
            method: 'GET'
        })
        return data.data.id
    }

    return {
        getPropertyDetails,
        getPropertyId
    };
}
