export const userPropertyStore = defineStore('property', () => {
    const user = useSupabaseUser()
    const { getPropertyDetails } = useProperty()
        const property = reactive({
        id:null,
        address_id : 0,
        name: '',
        capacity: 0,
        street: '',
        zip_code: '',
        city: '',
        country: '',
        house_number: 0,
        websiteURL: '',
        currency:'',
        type:''
    });

    	const getProperty = async () => {
            const response = await getPropertyDetails(user.value?.id)
                if(response.data) {
                    property.id = response.data.id
                    property.address_id = response.data.address_id
                    property.name = response.data.name
                    property.capacity = response.data.capacity
                    property.currency = response.data.currency
                    property.type = response.data.type
                    property.websiteURL = response.propertyAddress.websiteURL
                    
                    //PROPERTY ADDRESS
                    property.street = response.propertyAddress.street
                    property.zip_code = response.propertyAddress.zip_code
                    property.city = response.propertyAddress.city
                    property.country = response.propertyAddress.country
                    property.house_number = response.propertyAddress.house_number
                }
        }

    getProperty()
    
    return {
        property,
        getProperty
    }
})